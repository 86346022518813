import { gql, useQuery } from "@apollo/client";
import Head from "next/head";
import Button from "../components/Button";
import Card from "../components/Card";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useAuth } from "../services/auth/useAuth";
import { usePageMeta } from "../services/usePageMeta";
import styles from "./index.module.scss";

export default function Home() {
  const pageMeta = usePageMeta({
    title: "Welcome to Encapsulate Pharma",
  });

  const { isAuthenticated } = useAuth();

  const userQuery = useQuery(
    gql`
      query HomeGetAccounts {
        users {
          type
          user_accounts {
            account {
              type
            }
          }
        }
      }
    `,
    {
      skip: !isAuthenticated,
    }
  );

  const user = userQuery.data?.users?.[0];

  const hasAccountType = (type: "pbs" | "direct") => {
    return (user?.user_accounts || []).some(
      (userAccount) => userAccount.account.type === type
    );
  };
  const hasPBSAccount = hasAccountType("pbs");
  const hasDirectAccount = hasAccountType("direct");

  return (
    <div>
      <Head>{pageMeta}</Head>

      <Header />

      <main>
        <Container>
          <h1>Online Orders</h1>

          {!isAuthenticated && (
            <p>
              Please{" "}
              <Button isText className="hug" href="/auth/login/">
                login
              </Button>{" "}
              to your Encapsulate Pharma account to continue.
            </p>
          )}

          {user?.type === "staff" && (
            <p>
              Visit the{" "}
              <Button isText className="hug" href="/admin/accounts/">
                admin section
              </Button>{" "}
              to manage accounts.
            </p>
          )}

          <div className={styles.cards}>
            {hasPBSAccount && (
              <Card className={styles.card}>
                <h3 className="hug">PBS Order</h3>
                <p className="hug">
                  Create a new order under the Pharmaceutical Benefits Scheme.
                </p>
                <Button isBlock size="large" href="/orders/create/?type=pbs">
                  Start Order
                </Button>
              </Card>
            )}
            {hasDirectAccount && (
              <Card className={styles.card}>
                <h3 className="hug">Direct Account Order</h3>
                <p className="hug">
                  Create a new order for products not covered by the
                  Pharmaceutical Benefits Scheme.
                </p>
                <Button isBlock size="large" href="/orders/create/?type=direct">
                  Start Order
                </Button>
              </Card>
            )}
          </div>

          {hasPBSAccount && (
            <p>
              Download our{" "}
              <a
                href="/downloads/15-01-2023-patient-consent-and-co-payment-form.pdf"
                download
              >
                {new Date().getFullYear()} Patient Consent & Co-Payment Form
              </a>
              .
            </p>
          )}
        </Container>
      </main>
      <Footer />
    </div>
  );
}
