import Link from "next/link";
import styles from "./index.module.scss";
import classnames from "classnames";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export type Props = {
  children?: React.ReactNode;
  href?: string;
  className?: string;
  isBlock?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isText?: boolean;
  size?: "small" | "normal" | "large";
  color?: "black" | "primary" | "white";
} & Record<string, any>;

export default function Button(props: Props) {
  const {
    children,
    href,
    isBlock = false,
    className,
    isLoading = false,
    isDisabled = false,
    isText = false,
    size = "normal",
    color = "black",
    ...otherProps
  } = props;

  const classlist = classnames(
    styles.button,
    styles[size],
    styles[color],
    isBlock && styles.isBlock,
    isLoading && styles.isLoading,
    isDisabled && styles.isDisabled,
    isText && styles.isText,
    className
  );

  const loadingIcon = (
    <div className={styles.loadingIcon}>
      <AiOutlineLoading3Quarters />
    </div>
  );

  if (href) {
    return (
      <Link href={href}>
        <a className={classlist} {...otherProps}>
          {children}
          {loadingIcon}
        </a>
      </Link>
    );
  }

  return (
    <button
      className={classlist}
      disabled={isDisabled || isLoading}
      {...otherProps}
    >
      {children}
      {loadingIcon}
    </button>
  );
}
