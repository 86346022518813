import styles from "./index.module.scss";
import classnames from "classnames";

export type Props = {
  children?: React.ReactNode;
  className?: string;
  isFlat?: boolean;
} & Record<string, any>;

export default function Card(props: Props) {
  const { children, className, isFlat, ...otherProps } = props;

  return (
    <div
      className={classnames(styles.card, isFlat && styles.isFlat, className)}
      {...otherProps}
    >
      {children}
    </div>
  );
}
