import { useRouter } from "next/router";

export type Meta = {
  title: string;
  description?: string;
  image?: string;
  addTitlePrefix?: boolean;
};

export const usePageMeta = (meta: Meta) => {
  const { title, description, image, addTitlePrefix = true } = meta;

  const completeTitle = addTitlePrefix
    ? `Encapsulate Pharma - ${title}`
    : title;

  const router = useRouter();
  const url = `https://orders.encapsulatepharmalabs.com${router.asPath}`;

  return (
    <>
      <title>{completeTitle}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={completeTitle} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      <meta property="og:url" content={url} />
    </>
  );
};
