import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

type ReturnValue = {
  isAuthenticated: boolean;
  isLoading: boolean;
  tokenPayload: any;
  checkIsAuthenticated: () => void;
};

export const useAuth = (): ReturnValue => {
  const { isAuthenticated, isLoading, tokenPayload, checkIsAuthenticated } =
    useContext(AuthContext);

  return { isAuthenticated, isLoading, tokenPayload, checkIsAuthenticated };
};
