import jwt from "jsonwebtoken";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";

export const useRoles = () => {
  const { tokenPayload, isAuthenticated, isLoading } = useAuth();
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const rolesInToken =
      tokenPayload?.["https://hasura.io/jwt/claims"]?.[
        "x-hasura-allowed-roles"
      ];

    setRoles(rolesInToken || []);
  }, [isLoading, tokenPayload]);

  return {
    roles,
    isAuthenticated,
    isLoading: roles === null,
  };
};
