import classnames from "classnames";
import Container from "../Container";
import styles from "./index.module.scss";
export interface Props {
  className?: string;
  children?: React.ReactNode;
}

export default function Footer(props: Props) {
  const { children, className } = props;

  const currentYear = new Date().getFullYear();

  return (
    <footer className={classnames(styles.footer, className)}>
      <Container className={styles.container}>
        <p className={styles.legal}>
          Encapsulate Pharma • <a href="https://encapsulatepharma.com/contact/">Contact Us</a><br></br>
          Copyright © {currentYear} • All rights reserved<br></br>
        </p>
      </Container>
    </footer>
  );
}
