import styles from "./index.module.scss";
import classnames from "classnames";

export interface Props {
  children?: React.ReactNode;
  isWrapped?: boolean;
  isFlex?: boolean;
  className?: string;
  size?: "small" | "normal";
}

export default function Container(props: Props) {
  const { children, isWrapped, isFlex, size = "normal", className } = props;

  return (
    <div
      className={classnames(
        styles.container,
        className,
        styles[size],
        isWrapped && styles.isWrapped,
        isFlex && styles.isFlex
      )}
    >
      {children}
    </div>
  );
}
