import classnames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import Button from "../Button";
import Container from "../Container";
import styles from "./index.module.scss";
import logo from "./logo.png";
import { useOnClickOutside } from "../../services/useOnClickOutside";
import { FaDoorOpen, FaRegUserCircle } from "react-icons/fa";
import { useRoles } from "../../services/auth/useRoles";
import { useAuth } from "../../services/auth/useAuth";

export type NavItem = {
  href?: string;
  onClick?: Function;
  label: React.ReactNode;
};

export default function Header() {
  const { isAuthenticated, tokenPayload } = useAuth();
  const { roles } = useRoles();
  const router = useRouter();

  const isStaff = !!roles && roles.includes("staff");

  const isHomePage = router.pathname === "/";

  const [isAccountOpen, setIsAccountOpen] = useState(false);

  const accountRef = useRef(null);
  useOnClickOutside(accountRef, () => {
    setIsAccountOpen(false);
  });

  return (
    <header
      className={classnames(
        styles.header,
        isAccountOpen && styles.isAccountOpen
      )}
    >
      <Container isFlex>
        <Link href="/">
          <a className={styles.logo}>
            <div className={styles.logoImg}>
              <img src={logo.src} alt="Encapsulate Pharma" />
            </div>
          </a>
        </Link>

        <nav className={styles.nav}>
          {isStaff && (
            <>
              <Link href="/admin/users/">
                <a className={styles.item}>Users</a>
              </Link>
              <Link href="/admin/accounts/">
                <a className={styles.item}>Accounts</a>
              </Link>
            </>
          )}
        </nav>

        {!isStaff && (
          <div className={styles.buttons}>
            {!isAuthenticated && (
              <a className={styles.login} href="/auth/login/">
                Login
              </a>
            )}
            {!isHomePage && (
              <Button href="/" color="white" className="hug" size="small">
                New <span className={styles.hideOnMobile}>Order</span>
              </Button>
            )}
          </div>
        )}
        {isAuthenticated && (
          <div className={styles.account} ref={accountRef}>
            <button
              className={styles.accountToggle}
              onClick={() => setIsAccountOpen(!isAccountOpen)}
            >
              <FaRegUserCircle />
            </button>
            <div className={styles.accountItems}>
              {!!tokenPayload?.username && (
                <span
                  className={classnames(
                    styles.accountNavItem,
                    styles.staticAccountNavItem
                  )}
                >
                  {tokenPayload?.username}
                </span>
              )}
              <a
                className={classnames(styles.accountNavItem)}
                href="/auth/logout/"
              >
                <FaDoorOpen />
                Logout
              </a>
            </div>
          </div>
        )}
      </Container>
    </header>
  );
}
